<template>
  <div>
    <template v-for="(field, i) in form.fields">
      <b-row
        v-key="`menu-${i}`"
      >
        <b-col
          class="mt-2"
          cols="3"
        >
          <label class="menu-label">
            {{ $t(field.menu) }}
          </label>
        </b-col>
        <b-col>
          <template
            v-for="(attr, j) in field.attrs"
          >
            <Component
              :key="`section-${j}`"
              :is="attr.type"
              :lock="lock"
              :section="attr"
            />
          </template>
        </b-col>
      </b-row>
    </template>
  </div>
</template>
<script>

export default {
  components: {
    RadioSection: () => import('@custom/clients/lock/RadioSection'),
    CheckboxSection: () => import('@custom/clients/lock/CheckboxSection'),
    TextareaSection: () => import('@custom/clients/lock/TextareaSection'),
  },
  data: () => ({
    form: require('@/data/lock.json')
  }),
  props: {
    lock: {
      type: Object,
      default: () => {}
    },
  },
}
</script>
<style scoped src="@lazy/client/details/lock/_index.scss" lang="scss"/>
